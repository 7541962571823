<template>
  <!-- 
    画面: キャンセル規定一覧テーブル
    用途: 登録されたキャンセル規定のデータを一覧表示する
   -->
  <div>
    <!-- #region Alert -->

    <!-- #region newTable -->

    <table class="edge-type-table table table-sm table-striped table-bordered">
      <thead class="bg-light text-body text-center">
        <tr>
          <th>{{ $t("cancelPolicyListPage.name") }}</th>
          <th width="150">{{ $t("common.switchActive") }}</th>
          <th width="150">{{ $t("common.action") }}</th>
        </tr>
      </thead>

      <Draggable
        class="table-row-drag"
        v-model="tableItems"
        @end="onSortEnd"
        v-bind="dragOptions"
        v-if="canShowTable"
      >
        <transition-group
          type="transition"
          name="flip-list"
          tag="tbody"
        >
          <tr
            v-for="item in tableItems"
            :key="item.id"
          >
            <!-- #region 特集タイトル -->
            <td>
              {{ item.name }}
            </td>
            <!-- #endregion 特集タイトル -->

            <!-- #region 表示／非表示 -->
            <td class="text-center">
              <CSwitch
                class="swc sw-2"
                color="info"
                variant="opposite"
                :labelOn="$t('common.active')"
                :labelOff="$t('common.inActive')"
                :checked="item.status"
                @update:checked="onStatusChanged(item)"
              />
            </td>
            <!-- #endregion 表示／非表示 -->

            <!-- #region アクション -->
            <td class="text-center">
              <CButton
                color="info"
                square
                size="sm"
                @click="onEditClicked(item.id)"
              ><i class="icon cil-pencil mr-1"></i>{{ $t("common.edit") }}</CButton>
            </td>
            <!-- #endregion アクション -->
          </tr>
        </transition-group>
      </Draggable>
    </table>

    <!-- #endregion newTable -->
    <CAlert
      color="danger"
      class="mt-3 text-center"
      v-if="notFound"
    >
      {{ $t("cancelPolicyListPage.notFound") }}
    </CAlert>
    <!-- #endregion Alert -->
  </div>
</template>

<script>
import axios from "axios";
import CANCEL_POLICY_STATUS from "@/mixins/property";

export default {
  name: "CancelPolicyListTable",

  mixins: [CANCEL_POLICY_STATUS],

  data() {
    return {
      //#region Flag
      //#endregion Flag

      //#region Table

      /** 一覧表示データ */
      tableItems: [],

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },

  computed: {
    uid() {
      //return this.$route.params.UniqueId;
      return this.$store.state.uniqueId;
    },
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },
    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged(item) {
      // TODO: 有効無効ステータス更新リクエスト
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus(item);
    },

    /** 編集ボタン押下 */
    onEditClicked(id) {
      const cancelPolicyId = id;
      this.$router.push({
        path: `/operator/cancelPolicy/${cancelPolicyId}/edit`,
      });
    },
    onSortEnd() {
      // 一覧表の表示順IDを昇順の値にする
      this.tableItems.forEach((a, i) => (a.displayOrder = this.order[i]));
      this.reqPutDisplayOrder();
    },
    //#endregion Event

    //#region Request

    /** キャンセル規定取得 */
    getCancelPolicies() {
      const url = `/rest/hotel/${this.uid}/cancelpolicy`;
      // console.log("this.uid" + this.uid);

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          const datas = resp.data.policies;
          console.log(datas);

          this.response = datas;

          // 取得データを一覧表に流し込み
          this.pourTable(datas);
          this.createDisplayOrderIdAscList(datas);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          //this.PassError = err.response.status;
          this.loading = false;
        });
    },

    reqPutStatus(item) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/${item.id}/status`;
      const body = { status: item.status };
      console.log(url);

      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          //const response = resp.data;
          console.log("resp.data:" + resp.data);
          this.getCancelPolicies();
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },
    reqPutDisplayOrder() {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/order`;
      const body = [];
      this.tableItems.forEach((a) =>
        body.push({ id: a.id, displayOrder: a.displayOrder })
      );

      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          //this.reqGet();
          this.getCancelPolicies();
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },
    //#endregion Request

    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((data) => {
          this.tableItems.push({
            id: data.id,
            hotelId: data.hotelId,
            name: data.name,
            displayOrder: data.displayOrder,
            status: data.status,
          });
        });
    },
    /**
     * 一覧表のIDリストを昇順ソートし、表示順IDリストとする
     * Note: reqGet()までリスト不変の想定
     * @param item
     */
    createDisplayOrderIdAscList(item) {
      this.order = item.map((a) => a.id).sort((a, b) => a - b);
    },
    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    /**  */
    createTableItems(tableItem) {
      const tableItems = new Array(tableItem);
      return tableItems;
    },
    toggleStatus(status) {
      return status == this.CANCEL_POLICY_STATUS.VALID
        ? this.CANCEL_POLICY_STATUS.INVALID
        : this.CANCEL_POLICY_STATUS.VALID;
    },

    //#endregion Method
  },

  beforeMount() {
    this.getCancelPolicies();
  },
};
</script>
