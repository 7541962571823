<template>
  <!-- 
    画面: キャンセル規定一覧
    用途: キャンセル規定一覧を参照する
   -->
  <div>
    <!-- #region TODO -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
           <strong class="mr-3">{{ $t("cancelPolicyListPage.pageTitle") }}</strong>
            <CButton color="info" @click="onCreateClicked()">{{
              $t("common.create")
            }}</CButton>
          </CCardHeader>
          <CCardBody>
            <div class="table">
              <CancelPolicyListTable />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
// import axios from "axios";
import CancelPolicyListTable from "./components/ListTable";

export default {
  name: "CancelPolicyList",

  components: {
    CancelPolicyListTable,
  },

  props: {},

  data() {
    return {
      //#region Flag

      loading: false,

      //#endregion Flag
    };
  },

  methods: {
    //#region Method

    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `/operator/cancelPolicy/create`,
      });
    },

    //#endregion Method
  },
};
</script>